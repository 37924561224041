import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Textarea,
  VStack
} from '@chakra-ui/react';
import dateformat from 'dateformat';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  BRAND,
  HEADING_PROPS,
  PAGE_FLEX
} from '../../@chakra-ui/gatsby-plugin/theme';
import emailIcon from '../../images/ico_email_lgrey.svg';
import phoneIcon from '../../images/ico_phone_lgrey.svg';
import icon from '../../images/logo-white.svg';
import {
  emailFormValidation,
  generalFormValidation
} from '../../utils/formValidations.utils';
import { navbarItems } from '../../utils/linkCollections.utils';
import PostHook from '../../utils/postHook.utils';
import PageWrapper from '../misc/PageWrapper';

function Inquiry({ image }) {
  const { t } = useTranslation();

  const [isPostLoading, isPostError, postRequest, postResponse] = PostHook(
    'https://formspree.io/f/xzbobwly'
  );

  const {
    register,
    handleSubmit,
    getValues,

    formState: { errors },
  } = useForm();

  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);

  const [formValues] = useState({
    loading: '',
    unloading: '',
    loadingDate: '',
    unloadingDate: '',
    transportDetails: '',
    companyName: '',
    contactPerson: '',
    telephone: '',
    email: '',
    confirmation: false,
  });

  function onSubmit() {
    const formValues = getValues();
    postRequest(formValues);
  }

  useEffect(() => {
    if (postResponse) setIsFormSubmitSuccess(true);
  }, [postResponse]);

  return (
    <PageWrapper image={image}>
      <Flex id={t(navbarItems[3]?.url)} {...PAGE_FLEX}>
        <Heading {...HEADING_PROPS}>{t('inquiryTitle')}</Heading>

        <Flex mb="100px" flexDir={['column', 'row']} zIndex="1">
          <Flex
            onSubmit={handleSubmit(onSubmit)}
            maxW={['100vw', '1000px']}
            flexDir={['column']}
            pos="relative"
            as="form"
            mb="50px"
            w={['100vw', '700px']}
          >
            {isFormSubmitSuccess ? (
              <>
                <Box
                  zIndex={3}
                  d="inline-block"
                  bg="#081130"
                  position="absolute"
                  w="100%"
                  h="100%"
                  opacity={0.5}
                ></Box>
                <Heading
                  fontSize={['2xl', '2xl']}
                  pos="absolute"
                  my={['95%', '35%']}
                  textAlign="center"
                  zIndex={4}
                  bottom={[0, 'auto']}
                  p="2"
                  w="100%"
                  color="white"
                  d="inline-block"
                >
                  {t('formSubmitMessage')}
                </Heading>{' '}
              </>
            ) : null}

            {/* 1 */}

            <Stack
              mb="3"
              align="center"
              justify="center"
              flexDir={['column', 'row']}
              w={['95%', 'auto']}
            >
              <FormControl
                mr={['none', '5']}
                isInvalid={errors.loading}
                isRequired
              >
                <FormErrorMessage>
                  {errors.loading && errors.loading.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>{t('loadingLabel')}:</FormLabel>
                <Input
                  disabled={postResponse}
                  rounded="none"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="text"
                  required
                  name="loading"
                  {...register('loading', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.loading}
                />
              </FormControl>

              <FormControl
                style={{ marginTop: '0' }}
                isInvalid={errors.unloading}
                isRequired
              >
                <FormErrorMessage>
                  {errors.unloading && errors.unloading.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('unloadingLabel')}:
                </FormLabel>
                <Input
                  disabled={postResponse}
                  rounded="none"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="text"
                  required
                  name="unloading"
                  {...register('unloading', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.unloading}
                />
              </FormControl>
            </Stack>

            {/* 2 */}

            <Stack
              mb="3"
              align="center"
              justify="center"
              flexDir={['column', 'row']}
              w={['95%', 'auto']}
            >
              <FormControl
                mr={['none', '5']}
                isInvalid={errors.loadingDate}
                isRequired
              >
                <FormErrorMessage>
                  {errors.loadingDate && errors.loadingDate.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('loadingDateLabel')}:
                </FormLabel>
                <Input
                  disabled={postResponse}
                  rounded="none"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="date"
                  required
                  name="loadingDate"
                  min={dateformat(new Date(), 'yyyy-mm-dd')}
                  {...register('loadingDate', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.loadingDate}
                />
              </FormControl>

              <FormControl
                style={{ marginTop: '0' }}
                isInvalid={errors.unloadingDate}
                isRequired
              >
                <FormErrorMessage>
                  {errors.unloadingDate && errors.unloadingDate.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('unloadingDateLabel')}:
                </FormLabel>
                <Input
                  disabled={postResponse}
                  rounded="none"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="date"
                  required
                  min={dateformat(new Date(), 'yyyy-mm-dd')}
                  name="unloadingDate"
                  {...register('unloadingDate', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.unloadingDate}
                />
              </FormControl>
            </Stack>

            {/* 3 */}

            <Stack
              mb="3"
              align="center"
              justify="center"
              flexDir={['column', 'row']}
              w={['95%', 'auto']}
            >
              <FormControl isInvalid={errors.transportDetails} isRequired>
                <FormErrorMessage>
                  {errors.transportDetails && errors.transportDetails.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('transportDetailsLabel')}:
                </FormLabel>
                <Textarea
                  h="130px"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="text"
                  rounded="none"
                  required
                  name="transportDetails"
                  {...register('transportDetails', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.transportDetails}
                />
              </FormControl>
            </Stack>

            {/* 4 */}

            <Stack
              mb="3"
              align="center"
              justify="center"
              flexDir={['column', 'row']}
              w={['95%', 'auto']}
            >
              <FormControl
                mr={['none', '5']}
                isInvalid={errors.companyName}
                isRequired
              >
                <FormErrorMessage>
                  {errors.companyName && errors.companyName.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('companyNameLabel')}:
                </FormLabel>
                <Input
                  disabled={postResponse}
                  rounded="none"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="text"
                  required
                  name="companyName"
                  {...register('companyName', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.companyName}
                />
              </FormControl>

              <FormControl
                style={{ marginTop: '0' }}
                isInvalid={errors.contactPerson}
                isRequired
              >
                <FormErrorMessage>
                  {errors.contactPerson && errors.contactPerson.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('contactPersonLabel')}:
                </FormLabel>
                <Input
                  disabled={postResponse}
                  rounded="none"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="text"
                  required
                  name="contactPerson"
                  {...register('contactPerson', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.contactPerson}
                />
              </FormControl>
            </Stack>

            {/* 5 */}

            <Stack
              mb="3"
              align="center"
              justify="center"
              flexDir={['column', 'row']}
              w={['95%', 'auto']}
            >
              <FormControl
                mr={['none', '5']}
                isInvalid={errors.telephone}
                isRequired
              >
                <FormErrorMessage>
                  {errors.telephone && errors.telephone.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('telephoneLabel')}:
                </FormLabel>
                <Input
                  disabled={postResponse}
                  rounded="none"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="tel"
                  required
                  name="telephone"
                  {...register('telephone', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.telephone}
                />
              </FormControl>

              <FormControl
                style={{ marginTop: '0' }}
                isInvalid={errors.email}
                isRequired
              >
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>{t('emailLabel')}:</FormLabel>
                <Input
                  disabled={postResponse}
                  rounded="none"
                  bg={BRAND.white}
                  opacity={0.8}
                  type="email"
                  required
                  name="email"
                  {...register('email', {
                    ...emailFormValidation(t),
                  })}
                  defaultValue={formValues?.email}
                />
              </FormControl>
            </Stack>

            {/* 6 */}

            {/* <Stack
              my="5"
              align="center"
              justify="center"
              flexDir={['column', 'row']}
            >
              <FormControl isInvalid={errors.confirmation} isRequired>
                <FormErrorMessage>
                  {errors.confirmation && errors.confirmation.message}
                </FormErrorMessage>
                <Checkbox
                  color={BRAND.white}
                  type="text"
                  required
                  name="confirmation"
                  {...register('confirmation', {
                    ...checkboxValidation(t),
                  })}
                  defaultValue={formValues?.confirmation}
                >
                  {t('gdprLabel')}:
                </Checkbox>
              </FormControl>
            </Stack> */}

            <Button
              mt="2"
              mb="10"
              d="inline-block"
              rounded="none"
              w={
                isPostLoading
                  ? '200px'
                  : isPostError
                  ? '170px'
                  : isFormSubmitSuccess
                  ? '120px'
                  : '100px'
              }
              bg={BRAND.blue}
              _hover={{ bg: BRAND.darkBlue }}
              color={BRAND.white}
              onClick={handleSubmit(onSubmit)}
              disabled={isPostLoading}
            >
              {isPostLoading
                ? t('formSubmitLoading')
                : isPostError
                ? t('formSubmitFailed')
                : isFormSubmitSuccess
                ? t('formSubmitSuccess')
                : t('submitForm')}
            </Button>
          </Flex>

          <VStack
            color={BRAND.white}
            height={'100%'}
            w={['full', '330px']}
            flexDir={['row', 'column']}
            fontSize={['xs', 'md']}
          >
            <VStack
              pl={['auto', '20']}
              w="100%"
              align="flex-start"
              mt={['0', '6']}
            >
              <Box
                as="i"
                height="48px"
                w="48px"
                backgroundImage={phoneIcon}
              ></Box>
              <Link as="a" href="tel:+40747097770">
                +4 0747 097 770
              </Link>
              <Link as="a" href="tel:+40745542078">
                +4 0745 542 078
              </Link>
            </VStack>

            <VStack
              w="100%"
              pl={['auto', '20']}
              align="flex-start"
              pt={['0', '110px']}
              h={['100%', 'auto']}
            >
              <Box
                as="i"
                height="48px"
                w="48px"
                backgroundImage={emailIcon}
              ></Box>
              <Link
                as="a"
                href={`mailto:${t('inquiryEmail')}@thrustertrans.ro?subject=${t(
                  'inquiryEmailSubject'
                )}`}
              >
                {`${t('inquiryEmail')}@thrustertrans.ro`}
              </Link>
            </VStack>

            <VStack
              w="60%"
              pl={['auto', '5']}
              align="flex-start"
              pt={['0', '110px']}
              h={['100%', 'auto']}
              d={['none', 'block']}
            >
              <Box as="img" alt="thrustertrans-logo" src={icon} />
            </VStack>
          </VStack>
        </Flex>
      </Flex>
    </PageWrapper>
  );
}

export default Inquiry;
