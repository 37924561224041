import { useEffect, useRef, useState } from 'react';

function PostHook(url) {
  const [response, setResponse] = useState(null);
  const [isLoading, setLoading] = useState(null);
  const [isError, setError] = useState(null);

  const unmount = useRef(false);
  useEffect(() => {
    return () => {
      setError(null);
      unmount.current = true;
    };
  }, []);

  const postRequest = async (obj) => {
    setLoading(true);

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    })
      .then((res) => {
        const data = res;

        if (!unmount.current) {
          setResponse(data);
          setLoading(false);
          setError(null);
        }
      })
      .catch((err) => {
        if (!unmount.current) {
          const errorMsg = JSON.parse(err?.request?.response);

          setError(errorMsg);

          setLoading(false);
        }
      });
  };

  return [isLoading, isError, postRequest, response];
}

export default PostHook;
