import Icon from '@chakra-ui/icon';
import { Box, Flex, Link as ChakraLink, Spacer } from '@chakra-ui/layout';
import { keyframes } from '@chakra-ui/system';
import { Fade } from '@chakra-ui/transition';
import { Link, useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { CgClose } from 'react-icons/cg';
import { MdMenu } from 'react-icons/md';
import { BGCOLOR, BRAND } from '../../@chakra-ui/gatsby-plugin/theme';
import enFlag from '../../images/ico_flag_en.svg';
import huFlag from '../../images/ico_flag_hu.svg';
import roFlag from '../../images/ico_flag_ro.svg';
import icon from '../../images/logo-white.svg';
import { navbarItems } from '../../utils/linkCollections.utils';

function Navbar() {
  const { t } = useTranslation();
  const { languages, originalPath } = useI18next();
  const flagIcons = [huFlag, enFlag, roFlag];

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [lastYPos] = useState(0);
  const [isScrollAction, setIsScrollAction] = useState(false);
  const [pastLandingOptions, setScrollPastLandingOptions] = useState({
    fixed: false,
  });

  useEffect(() => {
    function handleScroll() {
      const LANDING_ARROW_HEIGHT = 700;

      const yPos = window.scrollY;
      const isScrollingUp = yPos > 10;
      const isScrollPastLanding = yPos > LANDING_ARROW_HEIGHT;

      setIsScrollAction(isScrollingUp);
      setScrollPastLandingOptions({
        ...pastLandingOptions,
        fixed: isScrollPastLanding,
      });
    }

    window.addEventListener('scroll', handleScroll, false);

    return () => {
      window.removeEventListener('scroll', handleScroll, false);
    };
  }, [
    lastYPos,
    setScrollPastLandingOptions,
    setIsScrollAction,
    pastLandingOptions,
  ]);

  function toggleOpen() {
    setMenuOpen((toggle) => !toggle);
  }

  const dropIn = keyframes`
  from { transform: translateY(-100px);opacity:0 }
  to { transform: translateY(0px); opacity:1}
`;

  return (
    <Flex
      as={'nav'}
      w="100vw"
      h={[isMenuOpen ? '100vh' : '60px', 'auto']}
      bg={BGCOLOR.navbarColor}
      shadow={isScrollAction ? 'md' : 'none'}
      position={['fixed']}
      flexDir={['column', 'row']}
      transition="all 300ms"
      align={['center', 'auto']}
      px={['auto', '6']}
      zIndex={[2]}
    >
      <ChakraLink
        as={Link}
        d="block"
        minW={['20px', '45px']}
        transition="all 300ms"
        w={['100px', '160px']}
        mt={[2, 'auto']}
        mb={[0, 'auto']}
        mr={['auto', '4']}
        ml={['auto', '8']}
        onClick={() => setMenuOpen(false)}
        to={originalPath}
      >
        <Box as="img" alt="thrustertrans-logo" src={icon} />
      </ChakraLink>
      <Fade in={[isMenuOpen, true]}>
        <Flex
          d={[!isMenuOpen ? 'none' : 'flex', 'flex']}
          my={['20', 'auto']}
          flexDir={['column', 'row']}
          animation={[`${dropIn} 280ms linear`, 'none']}
        >
          {navbarItems.map(({ name, url }, i) => (
            <ChakraLink
              borderBottom="3px solid transparent"
              _hover={{ borderBottomColor: BRAND.red, bg: 'rgba(0,0,0,0.15)' }}
              _selected={{
                borderBottomColor: BRAND.red,
                bg: 'rgba(0,0,0,0.15)',
              }}
              _focus={{ borderBottomColor: BRAND.red, bg: 'rgba(0,0,0,0.15)' }}
              px="3"
              transition={'all 150ms'}
              py={[3, '40px']}
              key={i}
              pointerEvents={[!isMenuOpen ? 'none' : 'auto', 'auto']}
              opacity={[isMenuOpen ? 1 : 0, 1]}
              mx={['auto', '3']}
              my={['3', 'auto']}
              fontSize="sm"
              fontWeight="semibold"
              color="white"
              as={Link}
              pos="relative"
              to={`/#${t(url)}`}
              onClick={() => setMenuOpen(false)}
            >
              {t(name)}
            </ChakraLink>
          ))}

          <Flex transition="all 150ms" mx={['auto', '4']} py={[3, '40px']}>
            {languages.map((lng, i) => (
              <Link key={i} to={originalPath} language={lng}>
                {/* <Tooltip label={lng.toUpperCase()}> */}
                <Box
                  mx={[1]}
                  h="6"
                  as="img"
                  alt={`${lng} flag`}
                  src={flagIcons[i]}
                />
                {/* </Tooltip> */}
              </Link>
            ))}
          </Flex>
        </Flex>
      </Fade>
      {!isMenuOpen && <Spacer />}

      <Box
        d={['block', 'none']}
        onClick={toggleOpen}
        color="white"
        float="right"
        ml="auto"
        position="fixed"
        right="5px"
        top="15px"
      >
        <Fade in={!isMenuOpen}>
          <Icon d={isMenuOpen && 'none'} fontSize="3xl" as={MdMenu} />
        </Fade>
        <Fade in={isMenuOpen}>
          <Icon d={!isMenuOpen && 'none'} fontSize="3xl" as={CgClose} />
        </Fade>
      </Box>
    </Flex>
  );
}

export default Navbar;
