import { Box, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { HEADING_PROPS, PAGE_FLEX } from '../../@chakra-ui/gatsby-plugin/theme';
import { navbarItems } from '../../utils/linkCollections.utils';
import PageWrapper from '../misc/PageWrapper';

function About({ image }) {
  const { t } = useTranslation();

  const paragraphs = {
    left: ['about-para-1', 'about-para-2', 'about-para-6'],
    right: ['about-para-3', 'about-para-4', 'about-para-5'],
  };
  return (
    <PageWrapper image={image} subpageNumber={1}>
      <Flex id={t(navbarItems[1]?.url)} {...PAGE_FLEX}>
        <Heading {...HEADING_PROPS}>{t('aboutTitle')}</Heading>

        <Flex
          zIndex="1"
          maxW={['100vw', '1000px']}
          flexDir={['column', 'row']}
          // bg="#D2DADF"
          pos="relative"
          mb="100px"
        >
          <Box
            zIndex={-1}
            opacity={0.8}
            height="100%"
            width="100%"
            display="inline-block"
            bg="#D2DADF"
            pos="absolute"
          ></Box>
          <VStack p={[5]} w={['full', '50%']} mr={['auto', 8]}>
            {paragraphs.left.map((text, i) => (
              <Text key={i} py={[2]} color="#151E3A">
                {t(text)}
              </Text>
            ))}
          </VStack>
          <VStack p={[5]} w={['full', '50%']}>
            {paragraphs.right.map((text, i) => (
              <Text key={i} py={[2]} color="#151E3A">
                {t(text)}
              </Text>
            ))}
          </VStack>
        </Flex>
      </Flex>
    </PageWrapper>
  );
}

export default About;
