import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  Textarea,
  VStack
} from '@chakra-ui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  BRAND,
  HEADING_PROPS,
  PAGE_FLEX
} from '../../@chakra-ui/gatsby-plugin/theme';
import emailIcon from '../../images/ico_email_lgrey.svg';
import facebookIcon from '../../images/ico_fb_lgrey.svg';
import mapIcon from '../../images/ico_map_lgrey.svg';
import phoneIcon from '../../images/ico_phone_lgrey.svg';
import icon from '../../images/logo-white.svg';
import {
  emailFormValidation,
  generalFormValidation
} from '../../utils/formValidations.utils';
import { navbarItems } from '../../utils/linkCollections.utils';
import PostHook from '../../utils/postHook.utils';
import PageWrapper from '../misc/PageWrapper';

function Contact({ image }) {
  const { t } = useTranslation();

  const [isPostLoading, isPostError, postRequest, postResponse] = PostHook(
    'https://formspree.io/f/xnqwqydn'
  );

  const {
    register,
    handleSubmit,
    getValues,

    formState: { errors },
  } = useForm();

  const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);

  const [formValues] = useState({
    name: '',
    telephone: '',
    email: '',
    subject: '',
    messageText: '',
    confirmation: false,
  });

  function onSubmit() {
    const formValues = getValues();
    postRequest(formValues);
  }

  useEffect(() => {
    if (postResponse) setIsFormSubmitSuccess(true);
  }, [postResponse]);

  return (
    <PageWrapper image={image}>
      <Flex id={t(navbarItems[4]?.url)} {...PAGE_FLEX}>
        <Heading {...HEADING_PROPS}>{t('contactTitle')}</Heading>

        <Flex mb="100px" flexDir={['column', 'row']} zIndex="1">
          <Flex
            onSubmit={handleSubmit(onSubmit)}
            maxW={['100vw', '1000px']}
            flexDir={['column']}
            pos="relative"
            as="form"
            mb="50px"
            w={['100vw', '700px']}
          >
            {isFormSubmitSuccess ? (
              <>
                <Box
                  zIndex={3}
                  d="inline-block"
                  bg="#081130"
                  position="absolute"
                  w="100%"
                  h="100%"
                  opacity={0.5}
                ></Box>
                <Heading
                  fontSize={['2xl', '2xl']}
                  pos="absolute"
                  my={['95%', '35%']}
                  textAlign={['center', 'flex-start']}
                  zIndex={4}
                  bottom={[0, 'auto']}
                  p="2"
                  w="100%"
                  color="white"
                  d="inline-block"
                >
                  {t('formSubmitMessage')}
                </Heading>{' '}
              </>
            ) : null}

            {/* 1 */}

            <Stack
              mb="3"
              w={['95%', '65%']}
              align={['center', 'flex-start']}
              flexDir={['column', 'row']}
            >
              <FormControl isInvalid={errors.name} isRequired>
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>{t('nameLabel')}:</FormLabel>
                <Input
                  bg={BRAND.white}
                  rounded="none"
                  opacity={0.8}
                  type="text"
                  required
                  name="name"
                  {...register('name', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.name}
                />
              </FormControl>
            </Stack>

            {/* 2 */}

            <Stack
              mb="3"
              w={['95%', '65%']}
              align={['center', 'flex-start']}
              flexDir={['column', 'row']}
            >
              <FormControl
                style={{ marginTop: '0' }}
                isInvalid={errors.email}
                isRequired
              >
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>{t('emailLabel')}:</FormLabel>
                <Input
                  bg={BRAND.white}
                  rounded="none"
                  opacity={0.8}
                  type="email"
                  required
                  name="email"
                  {...register('email', {
                    ...emailFormValidation(t),
                  })}
                  defaultValue={formValues?.email}
                />
              </FormControl>
            </Stack>

            {/* 3 */}

            <Stack
              mb="3"
              w={['95%', '65%']}
              align={['center', 'flex-start']}
              flexDir={['column', 'row']}
            >
              <FormControl isInvalid={errors.telephone} isRequired>
                <FormErrorMessage>
                  {errors.telephone && errors.telephone.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('telephoneLabel')}:
                </FormLabel>
                <Input
                  bg={BRAND.white}
                  rounded="none"
                  opacity={0.8}
                  type="tel"
                  required
                  name="telephone"
                  {...register('telephone', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.telephone}
                />
              </FormControl>
            </Stack>

            {/* 4 */}

            <Stack
              mb="3"
              w={['95%', '65%']}
              align={['center', 'flex-start']}
              flexDir={['column', 'row']}
            >
              <FormControl isInvalid={errors.subject} isRequired>
                <FormErrorMessage>
                  {errors.subject && errors.subject.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>{t('subjectLabel')}:</FormLabel>
                <Input
                  bg={BRAND.white}
                  rounded="none"
                  opacity={0.8}
                  type="text"
                  required
                  name="subject"
                  {...register('subject', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.subject}
                />
              </FormControl>
            </Stack>

            {/* 5 TEXTAREA */}

            <Stack
              mb="3"
              w={['95%', '65%']}
              align={['center', 'flex-start']}
              flexDir={['column', 'row']}
            >
              <FormControl isInvalid={errors.messageText} isRequired>
                <FormErrorMessage>
                  {errors.messageText && errors.messageText.message}
                </FormErrorMessage>
                <FormLabel color={BRAND.white}>
                  {t('messageTextLabel')}:
                </FormLabel>
                <Textarea
                  h="130px"
                  bg={BRAND.white}
                  rounded="none"
                  opacity={0.8}
                  type="text"
                  required
                  name="messageText"
                  {...register('messageText', {
                    ...generalFormValidation(t),
                  })}
                  defaultValue={formValues?.messageText}
                />
              </FormControl>
            </Stack>

            <Button
              rounded="none"
              mt="2"
              mb="10"
              d="inline-block"
              w={
                isPostLoading
                  ? '200px'
                  : isPostError
                  ? '170px'
                  : isFormSubmitSuccess
                  ? '120px'
                  : '100px'
              }
              bg={BRAND.blue}
              _hover={{ bg: BRAND.darkBlue }}
              color={BRAND.white}
              onClick={handleSubmit(onSubmit)}
              disabled={isPostLoading}
            >
              {isPostLoading
                ? t('formSubmitLoading')
                : isPostError
                ? t('formSubmitFailed')
                : isFormSubmitSuccess
                ? t('formSubmitSuccess')
                : t('submitForm')}
            </Button>
          </Flex>

          {/* SIDEBAR */}

          <VStack
            color={BRAND.white}
            w={['full', '330px']}
            flexDir={['row', 'column']}
            fontSize={['xs', 'md']}
            flexWrap={'wrap'}
          >
            <VStack
              pl={['auto', '20']}
              w="100%"
              mt={['0px', '6']}
              align={['center', 'flex-start']}
            >
              <Box
                as="i"
                height="48px"
                w="48px"
                backgroundImage={mapIcon}
              ></Box>
              <Text>Sat. Adea, nr. 402 </Text>
              <Text>Com. Sintea-Mare</Text>
              <Text>CP 317301, jud. Arad / RO</Text>
            </VStack>

            <VStack
              pl={['auto', '20']}
              w="100%"
              align={['center', 'flex-start']}
              pt="20px"
            >
              <Box
                as="i"
                height="48px"
                w="48px"
                backgroundImage={phoneIcon}
              ></Box>
              <Link as="a" href="tel:+40747097770">
                +4 0747 097 770
              </Link>
              <Link as="a" href="tel:+40745542078">
                +4 0745 542 078
              </Link>
            </VStack>

            <VStack
              w="100%"
              pl={['auto', '20']}
              align={['center', 'flex-start']}
              pt="20px"
              h={['auto']}
            >
              <Box
                as="i"
                height="48px"
                w="48px"
                backgroundImage={emailIcon}
              ></Box>
              <Link
                as="a"
                href={`mailto:office@thrustertrans.ro?subject=${t(
                  'officeEmailSubject'
                )}`}
              >
                office@thrustertrans.ro
              </Link>
              <Link
                as="a"
                href={`mailto:${t('inquiryEmail')}@thrustertrans.ro?subject=${t(
                  'inquiryEmailSubject'
                )}`}
              >
                {`${t('inquiryEmail')}@thrustertrans.ro`}
              </Link>
              <Link
                as="a"
                href={`mailto:${t('careerEmail')}@thrustertrans.ro?subject=${t(
                  'careerEmailSubject'
                )}`}
              >
                {`${t('careerEmail')}@thrustertrans.ro`}
              </Link>
            </VStack>

            <VStack
              w="100%"
              pl={['auto', '20']}
              align={['center', 'flex-start']}
              pt="20px"
              h={['auto']}
            >
              <Box
                as="i"
                height="48px"
                w="48px"
                backgroundImage={facebookIcon}
              ></Box>
              <Link
                as="a"
                target="_blank"
                href="https://www.facebook.com/thrustertrans/"
              >
                thrustertransro
              </Link>
            </VStack>

            <VStack
              w={['100%', '60%']}
              pl={['auto', '5']}
              align={['center', 'flex-start']}
              pt="20px"
              m="auto"
              h={['auto']}
            >
              <Box
                w={['50%', 'auto']}
                as="img"
                alt="thrustertrans-logo"
                src={icon}
              />
            </VStack>
          </VStack>
        </Flex>
      </Flex>
    </PageWrapper>
  );
}

export default Contact;
