import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

function HelmetComponent({ ogImage }) {
  const { t } = useTranslation();
  const { language } = useContext(I18nextContext);

  const title = 'Thruster Trans',
    description = t('about-para-1');

  const embeddableImage = ogImage;

  return (
    <>
      <Helmet>
        {/* HTML Language */}
        <html lang={language} />
        <meta charSet="utf-8" />
        {/* General tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="image" content={embeddableImage} />
        {/* OpenGraph tags */}
        <meta property="og:url" content={`${process.env.GATSBY_APP_URL}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={embeddableImage} />
        <meta property="og:type" content="website" />
        {/* <meta property="fb:app_id" content={seo.social.fbAppID} /> */}
        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        {/* <meta name="twitter:creator" content={seo.social.twitter} /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={embeddableImage} />
      </Helmet>
    </>
  );
}

export default HelmetComponent;
