export function generalFormValidation(t) {
  return {
    required: t('fieldRequired'),
    minLength: {
      value: 2,
      message: t('minTextLength'),
    },
    maxLength: {
      value: 80,
      message: t('maxTextLength'),
    },
  };
}

export function checkboxValidation(t) {
  return {
    required: t('confirmationRequired'),
  };
}

export function emailFormValidation(t) {
  return {
    required: t('fieldRequired'),
    pattern: {
      value:
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      message: t('wrongMailFormat'),
    },
    minLength: {
      value: 6,
      message: t('minMailLength'),
    },
    maxLength: {
      value: 60,
      message: t('maxMAilLength'),
    },
  };
}
