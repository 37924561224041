import { Flex } from '@chakra-ui/layout';
import React from 'react';
import '../../../src/style.css';
import { BGCOLOR } from '../../@chakra-ui/gatsby-plugin/theme';
import HelmetComponent from '../misc/Helmet';

function Layout({ children, ogImage }) {
  return (
    <>
      <HelmetComponent ogImage={ogImage} />
      <Flex
        bg={BGCOLOR.docBg}
        minH="100vh"
        w="100%"
        flexDir="column"
        overflow="hidden"
        position="relative"
      >
        {children}
      </Flex>
    </>
  );
}

export default Layout;
