import { Box, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import {
  BRAND,
  HEADING_PROPS,
  PAGE_FLEX
} from '../../@chakra-ui/gatsby-plugin/theme';
import { navbarItems } from '../../utils/linkCollections.utils';
import PageWrapper from '../misc/PageWrapper';
function Services({ image }) {
  const { t } = useTranslation();

  const serviceList = {
    left: [1, 2, 3, 4, 5, 6],
    right: [7, 8, 9, 10, 11, 12],
  };

  return (
    <PageWrapper image={image}>
      <Flex id={t(navbarItems[2]?.url)} {...PAGE_FLEX}>
        <Heading {...HEADING_PROPS}>{t('serviceTitle')}</Heading>

        <Flex
          zIndex="1"
          maxW={['100vw', '1000px']}
          flexDir={['column', 'row']}
          pos="relative"
          mb="100px"
        >
          <VStack
            justify={'flex-start'}
            py={[5]}
            w={['full', '50%']}
            mr={['auto', 5]}
            pos="relative"
            align={'flex-start'}
          >
            {mapServices(serviceList.left, t)}
          </VStack>
          <VStack
            justify={'flex-start'}
            py={[5]}
            w={['full', '50%']}
            mr={['auto', 8]}
            pos="relative"
            align={'flex-start'}
          >
            {mapServices(serviceList.right, t)}
          </VStack>
        </Flex>
      </Flex>
    </PageWrapper>
  );
}

function mapServices(servicesArray, t) {
  return servicesArray.map((number, i) => (
    <Flex w='100%' key={i}>
      <Box w="13px" h="100%" bg={i === 0 ? BRAND.red : BRAND.blue}></Box>
      <Text w='100%'
        pos="relative"
        _before={{
          h: '100px',
          bg: 'white',
          pos: 'absolute',
          top: '0px',
          display: 'inline-block',
          w: '20px',
        }}
        bg="#D2DADF"
        key={i}
        py={[2]}
        px={[3]}
        color="#151E3A"
      >
        {t(`service-${number}`)}
      </Text>
    </Flex>
  ));
}

export default Services;
