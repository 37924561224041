import { Box, Flex, Heading } from '@chakra-ui/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { BRAND, PAGESIZE } from '../../@chakra-ui/gatsby-plugin/theme';
import arrow from '../../images/red-arrow.svg';
import { navbarItems } from '../../utils/linkCollections.utils';
import Navbar from '../Layout/Navbar';

function Landing({ image: { bg, name } }) {
  const { t } = useTranslation();
  return (
    <Flex id={t(navbarItems[0]?.url)} {...PAGESIZE} maxW="100vw" pos="relative">
      <Box
        as={GatsbyImage}
        pos="absolute"
        w="100%"
        h="100%"
        zIndex={0}
        image={bg}
        alt={name}
      />
      <Navbar />
      <Box
        pointerEvents={0}
        zIndex={1}
        d="inline-block"
        bg="#081130"
        position="absolute"
        w="100%"
        h="100%"
        opacity={0.8}
      />

      <Box
        display={['none', 'block']}
        pos="fixed"
        right="-215px"
        w="auto"
        h="100vh"
        as="img"
        src={arrow}
        zIndex={3}
      />

      <Heading
        as="h1"
        textAlign="left"
        fontSize={['23px', '30px']}
        ml={['40px', '100px']}
        zIndex={1}
        color="#D2DADF"
        d="inline-block"
        w={['85%', '700px']}
        h={['fit-content']}
        mt={['120px', '180px']}
        pos="relative"
        lineHeight={1.5}
        fontWeight="200"
        _before={{
          width: '175px',
          height: ['2px', '3px'],
          content: "''",
          bg: BRAND.red,
          display: 'inline-block',
          bottom: ['-25px'],
          pos: 'absolute',
          rounded: 'xl',
        }}
      >
        {t('landingTitle')}
      </Heading>
    </Flex>
  );
}

export default Landing;
