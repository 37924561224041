import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import * as React from 'react';
import About from '../components/About/About';
import Contact from '../components/Contact/Contact';
import Inquiry from '../components/Inquiry/Inquiry';
import Landing from '../components/Landing/Landing';
import Layout from '../components/Layout/Layout';
import Services from '../components/Services/Services';

const IndexPage = ({ data }) => {
  const images = {
    landing: {
      bg: getImage(data.images.edges[0].node),
      name: data.images.edges[0].node.fixed.originalName,
    },
    about: {
      bg: getImage(data.images.edges[1].node),
      name: data.images.edges[1].node.fixed.originalName,
    },
    services: {
      bg: getImage(data.images.edges[2].node),
      name: data.images.edges[2].node.fixed.originalName,
    },

    inquiry: {
      bg: getImage(data.images.edges[3].node),
      name: data.images.edges[3].node.fixed.originalName,
    },

    contact: {
      bg: getImage(data.images.edges[4].node),
      name: data.images.edges[4].node.fixed.originalName,
    },
  };

  const ogImage = data.images.edges[5].node.fixed.src;

  return (
    <Layout ogImage={ogImage}>
      <Landing image={images.landing} />
      <About image={images.about} />
      <Services image={images.services} />
      <Inquiry image={images.inquiry} />
      <Contact image={images.contact} />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query Locale($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    images: allImageSharp(sort: { order: ASC, fields: fixed___originalName }) {
      edges {
        node {
          gatsbyImageData(
            formats: WEBP
            placeholder: BLURRED
            layout: FULL_WIDTH
            quality: 10
            webpOptions: { quality: 10 }
          )
          fixed {
            originalName
            src
          }
        }
      }
    }
  }
`;
